import './style.css'
import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const TYPES = ['yearly', 'monthly']

const containerClass = classNames({
  'pricing-offers-toggle-container': true,
})

class Toggle extends Component {

  // Handlers
  handleClick = (type) => {
    const { onClick } = this.props

    if (!onClick) {
      return
    }

    onClick(type)
  }


  // Render
  render() {
    const {
      current,
      intl: {
        formatMessage,
      },
    } = this.props

    const yearlyClass = classNames({
      'pricing-offers-toggle-yearly': true,
      'pricing-offers-toggle-current': current === TYPES[0],
    })

    const monthlyClass = classNames({
      'pricing-offers-toggle-monthly': true,
      'pricing-offers-toggle-current': current === TYPES[1],
    })

    return (
      <div className={containerClass}>
        <div className={yearlyClass} onClick={() => this.handleClick(TYPES[0])}>
          {formatMessage({ id: 'pricing.offers.toggle.yearly.title' })}
        </div>
        <div className={monthlyClass} onClick={() => this.handleClick(TYPES[1])}>
          {formatMessage({ id: 'pricing.offers.toggle.monthly.title' })}
        </div>
      </div>
    )
  }
}

Toggle.propTypes = {
  current: PropTypes.oneOf(TYPES).isRequired,
  onClick: PropTypes.func.isRequired,
}

Toggle.defaultProps = {
  current: TYPES[0],
  onClick: undefined,
}

export default injectIntl(Toggle)

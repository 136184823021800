import './style.css'
import React, { Component, Fragment } from 'react'
import classNames from 'classnames'
import { Container, Row, Col, Visible } from 'react-grid-system'
import { injectIntl, FormattedMessage } from 'react-intl'

import I18nLink from '../../../components/I18nLink'
import Space from '../../../components/Space'
import Card from './components/Card'
import Toggle from './components/Toggle'

const GATSBY_APP_URL = process.env.GATSBY_APP_URL
const TYPES = ['yearly', 'monthly']
const CURRENCY = '€'
const PRICES = {
  yearly: '7',
  monthly: '9',
}

const containerClass = classNames({
  'pricing-offers-container': true,
})

class Offers extends Component {

  constructor(props) {
    super(props)

    this.state = {
      billingType: TYPES[0],
    }
  }


  // Handlers
  handleToggleClick = (type) => {
    this.setState({
      billingType: type,
    })
  }

  handleIntercomClick = () => {
    if (typeof window !== 'undefined') {
      window.Intercom('show')
    }
  }


  // Render
  render() {
    const {
      intl: {
        formatMessage,
      },
    } = this.props

    const { billingType } = this.state

    const buttons = {
      free: {
        text: formatMessage({ id: 'pricing.offers.card.free.button' }),
        link: `${GATSBY_APP_URL}/sign-up?utm_source=wuha&utm_medium=cta&utm_content=pricing-free`,
      },
      standard: {
        text: formatMessage({ id: 'pricing.offers.card.standard.button' }),
        link: `${GATSBY_APP_URL}/sign-up?utm_source=wuha&utm_medium=cta&utm_content=pricing-standard&plan=personal-${billingType}-eur`,
      },
      business: {
        text: formatMessage({ id: 'pricing.offers.card.business.button' }),
        onClick: this.handleIntercomClick,
      },
    }

    const allMainFeatures = this.renderAllMainFeaturesFeature()
    const features = {
      free: [
        {
          content: allMainFeatures,
          type: 'free',
        },
        {
          content: formatMessage({ id: 'pricing.offers.card.features.free.indexing' }),
          type: 'free',
        },
        {
          content: formatMessage({ id: 'pricing.offers.card.features.free.history' }),
          type: 'free',
        },
      ],
      standard: [
        {
          content: allMainFeatures,
          type: 'free',
        },
        {
          content: formatMessage({ id: 'pricing.offers.card.features.standard.indexing' }),
          type: 'standard',
        },
        {
          content: formatMessage({ id: 'pricing.offers.card.features.standard.history' }),
          type: 'standard',
        },
        {
          content: formatMessage({ id: 'pricing.offers.card.features.standard.fs' }),
          type: 'standard',
        },
      ],
      business: [
        {
          content: allMainFeatures,
          type: 'free',
        },
        {
          content: formatMessage({ id: 'pricing.offers.card.features.standard.indexing' }),
          type: 'standard',
        },
        {
          content: formatMessage({ id: 'pricing.offers.card.features.standard.history' }),
          type: 'standard',
        },
        {
          content: formatMessage({ id: 'pricing.offers.card.features.standard.fs' }),
          type: 'standard',
        },
        {
          content: formatMessage({ id: 'pricing.offers.card.features.business.customApps' }),
          type: 'business',
        },
        {
          content: formatMessage({ id: 'pricing.offers.card.features.business.dedicatedSupport' }),
          type: 'business',
        },
        {
          content: formatMessage({ id: 'pricing.offers.card.features.business.personalizedSupport' }),
          type: 'business',
        },
        {
          content: formatMessage({ id: 'pricing.offers.card.features.business.analytics' }),
          type: 'business',
        },
      ],
    }

    return (
      <Container>
        <Row>
          <Col md={12}>
            <div className={containerClass}>
              <Toggle
                onClick={this.handleToggleClick}
                current={billingType}
              />
            </div>
          </Col>
        </Row>
        <Space type="xl" />
        <Row>
          <Col xs={12} md={4}>
            <div>
              <Card
                type="free"
                title={formatMessage({ id: 'pricing.offers.card.free.title' })}
                price={formatMessage({ id: 'pricing.offers.card.free.price' })}
                features={features.free}
                button={buttons.free}
              />
              <Visible xs sm>
                <Space type="l" />
              </Visible>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <Card
                type="standard"
                title={formatMessage({ id: 'pricing.offers.card.standard.title' })}
                price={<FormattedMessage id="pricing.offers.card.standard.price" values={{ currency: CURRENCY, price: PRICES[billingType] }} />}
                caption={formatMessage({ id: 'pricing.offers.card.standard.caption' })}
                features={features.standard}
                button={buttons.standard}
              />
              <Visible xs sm>
                <Space type="l" />
              </Visible>
            </div>
          </Col>
          <Col xs={12} md={4}>
            <div>
              <Card
                type="business"
                title={formatMessage({ id: 'pricing.offers.card.business.title' })}
                price={formatMessage({ id: 'pricing.offers.card.business.price' })}
                features={features.business}
                button={buttons.business}
              />
            </div>
          </Col>
        </Row>

      </Container>
    )
  }

  renderAllMainFeaturesFeature = () => {
    const {
      intl: {
        formatMessage,
      },
    } = this.props

    const allMainFeatures = formatMessage({ id: 'pricing.offers.card.features.allMain' })
    const see = formatMessage({ id: 'pricing.offers.card.features.see' })

    return (
      <Fragment>
        {`${allMainFeatures} • `}
        <I18nLink to="/pricing#pricing-main-features">
          {see}
        </I18nLink>
      </Fragment>
    )
  }
}

export default injectIntl(Offers)

import React, { Fragment } from 'react'
import { injectIntl } from 'react-intl'

import withContext from '../components/withContext'
import injectContext from '../components/injectContext'
import withIntl from '../components/withIntl'
import Space from '../components/Space'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Navigation from '../scenes/Navigation'
import Header from '../scenes/Header'
import Offers from '../scenes/Pricing/Offers'
import Features from '../scenes/Pricing/Features'
import FAQ from '../scenes/Pricing/FAQ'
import SecurityMini from '../scenes/SecurityMini'
import Footer from '../scenes/Footer'

const Pricing = ({ intl: { formatMessage } }) => (
  <Layout>
    <SEO
      metaTitle={formatMessage({ id: 'meta.pricing.title' })}
      metaDescription={formatMessage({ id: 'meta.pricing.description' })}
    />
    <Navigation />
    <Header
      crumbs={[
        { name: formatMessage({ id: 'breadcrumb.wuha' }), path: '/' },
        { name: formatMessage({ id: 'breadcrumb.pricing' }), path: '/pricing' },
      ]}
      title={formatMessage({ id: 'pricing.header.title' })}
      description={
        <Fragment>
          {formatMessage({ id: 'pricing.header.description.1' })}
          <br />
          {formatMessage({ id: 'pricing.header.description.2' })}
        </Fragment>
      }
    />
    <Space type="xl" />
    <Offers />
    <Space type="xl" />
    <Features />
    <Space type="xl" />
    <FAQ />
    <Space type="giant" />
    <SecurityMini />
    <Footer />
  </Layout>
)

export default withContext(injectContext(withIntl(injectIntl(Pricing))))

import './style.css'
import React, { Component, Fragment } from 'react'
import { Col, Hidden, Visible } from 'react-grid-system'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Image from '../../../../../components/Image'
import Space from '../../../../../components/Space'

import chevronDownImage from '../../../../../img/icons/icon_chevron_down_purple.svg'
import Inline from '../../../../../components/Inline'

const qaContainerClass = classNames({
  'pricing-faq-qa-container': true,
})
const answerClass = classNames({
  'pricing-faq-qa-answer': true,
})
const questionClass = classNames({
  'pricing-faq-qa-question': true,
})
const foldableQuestionClass = classNames({
  'pricing-faq-qa-question': true,
  'pricing-faq-qa-question-foldable': true,
})


class QuestionAnswer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isFolded: true,
    }
  }

  // Handlers
  handleFoldableClick = () => {
    const { isFolded } = this.state
    this.setState({
      isFolded: !isFolded,
    })
  }


  // Render
  render() {
    const {
      isFoldable,
    } = this.props

    const qa = isFoldable ? this.renderFoldableQA() : this.renderQA()

    return qa
  }

  renderQA = () => {
    const {
      question,
      answer,
    } = this.props

    return (
      <Col md={6}>
        <div className={qaContainerClass}>
          <p className={questionClass}>
            {question}
          </p>
          <Hidden xs sm>
            <Space type="s" />
          </Hidden>
          <Visible xs sm>
            <Space type="xxs" />
          </Visible>
          <p className={answerClass}>
            {answer}
          </p>
          <Space type="m" />
        </div>
      </Col>
    )
  }

  renderFoldableQA = () => {
    const {
      question,
      answer,
    } = this.props

    const {
      isFolded,
    } = this.state

    const chevronClass = classNames({
      'pricing-faq-qa-chevron': true,
      'pricing-faq-qa-chevron-up': !isFolded,
    })

    return (
      <Fragment>
        <div className={qaContainerClass}>
          <div className={foldableQuestionClass} onClick={this.handleFoldableClick}>
            {question}

            <Inline type="xs" />
            <div className={chevronClass}>
              <Image
                src={chevronDownImage}
                alt="chevron"
                height={16}
              />
            </div>
          </div>
          {
            !isFolded &&
            <Fragment>
              <Space type="s" />
              <div className={answerClass}>
                {answer}
              </div>
            </Fragment>
          }
        </div>
        <Space type="s" />
      </Fragment>
    )
  }

}

QuestionAnswer.propTypes = {
  isFoldable: PropTypes.bool.isRequired,
  question: PropTypes.string.isRequired,
  answer: PropTypes.node.isRequired,
}

QuestionAnswer.defaultProps = {
  isFoldable: false,
}

export default QuestionAnswer

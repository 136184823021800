import './style.css'
import React, { Component, Fragment } from 'react'
import { Container, Row, Col } from 'react-grid-system'
import { injectIntl } from 'react-intl'
import classNames from 'classnames'

import Image from '../../../components/Image'
import Space from '../../../components/Space'

import grayStarPicture from '../../../img/icons/icon_star_gray.svg'
import Inline from '../../../components/Inline'

const featureContainerClass = classNames({
  'pricing-features-feature-container': true,
})
const titleClass = classNames({
  'pricing-features-title': true,
})

class Features extends Component {
  render() {
    return (
      <Container>
        {this.renderTitle()}
        <Space type="xl" />
        {this.renderFeatures()}
      </Container>
    )
  }

  renderTitle = () => {
    const {
      intl: {
        formatMessage,
      },
    } = this.props

    return (
      <Row>
        <Col md={12}>
          <h4
            id="pricing-main-features"
            className={titleClass}
          >
            {formatMessage({ id: 'pricing.features.title' })}
          </h4>
        </Col>
      </Row>
    )
  }
  renderFeatures = () => {
    const {
      intl: {
        formatMessage,
      },
    } = this.props

    return (
      <Fragment>
        <Row>
          {this.renderFeature(formatMessage({ id: 'pricing.features.1' }))}
          {this.renderFeature(formatMessage({ id: 'pricing.features.2' }))}
          {this.renderFeature(formatMessage({ id: 'pricing.features.3' }))}
          {this.renderFeature(formatMessage({ id: 'pricing.features.4' }))}
          {this.renderFeature(formatMessage({ id: 'pricing.features.5' }))}
          {this.renderFeature(formatMessage({ id: 'pricing.features.6' }))}
          {this.renderFeature(formatMessage({ id: 'pricing.features.7' }))}
          {this.renderFeature(formatMessage({ id: 'pricing.features.8' }))}
        </Row>
      </Fragment>
    )
  }

  renderFeature = (feature) => {
    return (
      <Col md={4} >
        <div className={featureContainerClass}>
          <Image
            src={grayStarPicture}
            alt="star"
            height={24}
          />
          <Space type="xxs" />
          <Inline type="xs" />
          <span>{feature}</span>
        </div>
        <Space type="l" />
      </Col>
    )
  }
}

export default injectIntl(Features)

import './style.css'
import React, { Component, Fragment } from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Container, Row, Col } from 'react-grid-system'
import classNames from 'classnames'

import I18nLink from '../../../components/I18nLink'
import Space from '../../../components/Space'
import QuestionAnswer from './components/QuestionAnswer'

const GATSBY_APP_URL = process.env.GATSBY_APP_URL

const titleClass = classNames({
  'pricing-faq-title': true,
})

const subtitleClass = classNames({
  'pricing-faq-subtitle': true,
})

class FAQ extends Component {

  render() {
    return (
      <Container>
        {this.renderTitle()}
        <Space type="xl" />
        {this.renderPriceQuestions()}
        <Space type="giant" />
        {this.renderDataQuestions()}
        <Space type="giant" />
        {this.renderProductQuestions()}
      </Container>
    )
  }

  renderTitle = () => {
    const {
      intl: {
        formatMessage,
      },
    } = this.props

    return (
      <Row>
        <Col md={12}>
          <h2 className={titleClass}>
            {formatMessage({ id: 'pricing.faq.title' })}
          </h2>
        </Col>
      </Row>
    )
  }

  renderPriceQuestions = () => {
    const {
      intl: {
        formatMessage,
      },
    } = this.props

    const freePlan = formatMessage({ id: 'pricing.faq.questions.plan.free' })
    const standardPlan = formatMessage({ id: 'pricing.faq.questions.plan.standard' })

    return (
      <Fragment>
        <h3 className={subtitleClass}>
          {formatMessage({ id: 'pricing.faq.price.title' })}
        </h3>
        <Space type="m" />
        <Row>
          <QuestionAnswer
            question={formatMessage({ id: 'pricing.faq.price.1.question' })}
            answer={formatMessage({ id: 'pricing.faq.price.1.answer' })}
          />
          <QuestionAnswer
            question={formatMessage({ id: 'pricing.faq.price.2.question' })}
            answer={formatMessage({ id: 'pricing.faq.price.2.answer' })}
          />
          <QuestionAnswer
            question={formatMessage({ id: 'pricing.faq.price.3.question' })}
            answer={formatMessage({ id: 'pricing.faq.price.3.answer' })}
          />
          <QuestionAnswer
            question={formatMessage({ id: 'pricing.faq.price.4.question' })}
            answer={formatMessage({ id: 'pricing.faq.price.4.answer' })}
          />
          <QuestionAnswer
            question={formatMessage({ id: 'pricing.faq.price.5.question' })}
            answer={<FormattedMessage id="pricing.faq.price.5.answer" values={{ freePlan: <b>{freePlan}</b> }} />}
          />
          <QuestionAnswer
            question={formatMessage({ id: 'pricing.faq.price.6.question' })}
            answer={
              <Fragment>
                {formatMessage({ id: 'pricing.faq.price.6.answer' })}
                <ul>
                  <li>
                    <FormattedMessage
                      id="pricing.faq.price.6.answer.1"
                      values={{
                        standardPlan: <b>{standardPlan}</b>,
                      }}
                    />
                  </li>
                  <li>
                    <FormattedMessage
                      id="pricing.faq.price.6.answer.2"
                      values={{
                        freePlan: <b>{freePlan}</b>,
                      }}
                    />
                  </li>
                </ul>
              </Fragment>
            }
          />
        </Row>
      </Fragment>
    )
  }

  renderDataQuestions = () => {
    const {
      intl: {
        formatMessage,
      },
    } = this.props

    return (
      <Fragment>
        <h3 className={subtitleClass}>
          {formatMessage({ id: 'pricing.faq.data.title' })}
        </h3>
        <Space type="m" />
        <Row>
          <Col md={6} >
            <QuestionAnswer
              question={formatMessage({ id: 'pricing.faq.data.1.question' })}
              answer={
                <Fragment>
                  {formatMessage({ id: 'pricing.faq.data.1.answer' })}<br />
                  <Space type="xxs" />
                  <I18nLink to="/data-protection">{formatMessage({ id: 'pricing.faq.questions.keyword.knowMore' })}</I18nLink>
                </Fragment>
              }
              isFoldable={true}
            />
            <QuestionAnswer
              question={formatMessage({ id: 'pricing.faq.data.2.question' })}
              answer={
                <Fragment>
                  <FormattedMessage id="pricing.faq.data.2.answer" values={{
                    link: <I18nLink to="/privacy-policy">{formatMessage({ id: 'pricing.faq.questions.keyword.here' })}</I18nLink>,
                  }} />
                </Fragment>
              }
              isFoldable={true}
            />
            <QuestionAnswer
              question={formatMessage({ id: 'pricing.faq.data.3.question' })}
              answer={
                <Fragment>
                  {formatMessage({ id: 'pricing.faq.data.3.answer' })}<br />
                  <Space type="xxs" />
                  <I18nLink to="/data-management">{formatMessage({ id: 'pricing.faq.questions.keyword.knowMore' })}</I18nLink>
                </Fragment>
              }
              isFoldable={true}
            />
          </Col>
          <Col md={6} >
            <QuestionAnswer
              question={formatMessage({ id: 'pricing.faq.data.4.question' })}
              answer={
                <Fragment>
                  {formatMessage({ id: 'pricing.faq.data.4.answer' })}<br />
                  <Space type="xxs" />
                  <I18nLink to="/data-management#19">{formatMessage({ id: 'pricing.faq.questions.keyword.knowMore' })}</I18nLink>
                </Fragment>
              }
              isFoldable={true}
            />
            <QuestionAnswer
              question={formatMessage({ id: 'pricing.faq.data.5.question' })}
              answer={
                <FormattedMessage
                  id="pricing.faq.data.5.answer"
                  values={{
                    link: <a href={`${GATSBY_APP_URL}/profile/privacy`}>{formatMessage({ id: 'pricing.faq.questions.keyword.privacy' })}</a>,
                  }}
                />
              }
              isFoldable={true}
            />
            <QuestionAnswer
              question={formatMessage({ id: 'pricing.faq.data.6.question' })}
              answer={formatMessage({ id: 'pricing.faq.data.6.answer' })}
              isFoldable={true}
            />
          </Col>
        </Row>
      </Fragment>
    )
  }

  renderProductQuestions = () => {
    const {
      intl: {
        formatMessage,
      },
    } = this.props

    return (
      <Fragment>
        <h3 className={subtitleClass}>
          {formatMessage({ id: 'pricing.faq.product.title' })}
        </h3>
        <Space type="m" />
        <Row>
          <Col md={6} >
            <QuestionAnswer
              question={formatMessage({ id: 'pricing.faq.product.1.question' })}
              answer={
                <Fragment>
                  {formatMessage({ id: 'pricing.faq.product.1.answer' })}
                  <ul>
                    <li>
                      <FormattedMessage
                        id="pricing.faq.product.1.answer.1"
                        values={{
                          link: <a href={GATSBY_APP_URL}>{GATSBY_APP_URL}</a>,
                        }}
                      />
                    </li>
                    <li>
                      <FormattedMessage
                        id="pricing.faq.product.1.answer.2"
                        values={{
                          link: <a href={`${GATSBY_APP_URL}/profile/extension`}>{formatMessage({ id: 'pricing.faq.questions.keyword.theExtension' })}</a>,
                        }}
                      />
                    </li>
                    <ul>
                      <li>
                        <FormattedMessage
                          id="pricing.faq.product.1.answer.2.1"
                          values={{
                            link: <I18nLink to="/artificial-intelligence">{formatMessage({ id: 'pricing.faq.questions.keyword.google' })}</I18nLink>,
                          }}
                        />
                      </li>
                      <li>
                        <FormattedMessage
                          id="pricing.faq.product.1.answer.2.2"
                          values={{
                            link: <I18nLink to="/integrations">{formatMessage({ id: 'pricing.faq.questions.keyword.connectedApps' })}</I18nLink>,
                          }}
                        />
                      </li>
                    </ul>
                  </ul>
                  {formatMessage({ id: 'pricing.faq.product.1.answer.3' })}
                </Fragment>
              }
              isFoldable={true}
            />
            <QuestionAnswer
              question={formatMessage({ id: 'pricing.faq.product.2.question' })}
              answer={
                <FormattedMessage
                  id="pricing.faq.product.2.answer"
                  values={{
                    link: <a href={GATSBY_APP_URL}>{GATSBY_APP_URL}</a>,
                  }}
                />
              }
              isFoldable={true}
            />
          </Col>
          <Col md={6} >
            <QuestionAnswer
              question={formatMessage({ id: 'pricing.faq.product.3.question' })}
              answer={
                <Fragment>
                  {formatMessage({ id: 'pricing.faq.product.3.answer' })}<br />
                  <FormattedMessage
                    id="pricing.faq.product.3.answer.1"
                    values={{
                      link: <I18nLink to="/integrations">{formatMessage({ id: 'pricing.faq.questions.keyword.here' })}</I18nLink>,
                    }}
                  />
                </Fragment>
              }
              isFoldable={true}
            />
            <QuestionAnswer
              question={formatMessage({ id: 'pricing.faq.product.4.question' })}
              answer={
                <Fragment>
                  {formatMessage({ id: 'pricing.faq.product.4.answer' })}<br />
                  <Space type="xxs" />
                  <I18nLink to="/artificial-intelligence#strengths-formats">
                    {formatMessage({ id: 'pricing.faq.questions.keyword.knowMore' })}
                  </I18nLink>
                </Fragment>
              }
              isFoldable={true}
            />
          </Col>
        </Row>
      </Fragment>
    )
  }
}

export default injectIntl(FAQ)

import './style.css'
import React, { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import Image from '../../../../../../../components/Image'

import grayStarPicture from '../../../../../../../img/icons/icon_star_gray.svg'
import yellowStarPicture from '../../../../../../../img/icons/icon_star_yellow.svg'
import purpleStarPicture from '../../../../../../../img/icons/icon_star_purple.svg'
import Inline from '../../../../../../../components/Inline'

const TYPES = ['free', 'standard', 'business']
const IMAGE_SRC = {
  free: grayStarPicture,
  standard: yellowStarPicture,
  business: purpleStarPicture,
}

const containerClass = classNames({
  'pricing-offers-card-staritem-container': true,
})
const featureFreeClass = classNames({
  'pricing-offers-card-staritem': true,
})

class StarItem extends Component {
  render() {
    const {
      content,
      type,
    } = this.props

    return (
      <div className={containerClass}>
        <Image
          src={IMAGE_SRC[type]}
          alt="star"
          height={24}
        />
        <Inline type="xxs" />
        <span className={featureFreeClass}>{content}</span>
      </div>
    )
  }
}

StarItem.propTypes = {
  content: PropTypes.node.isRequired,
  type: PropTypes.oneOf(TYPES).isRequired,
}

export default StarItem

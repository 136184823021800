import './style.css'
import React, { Component, Fragment } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import Button from '../../../../../components/Button'
import StarItem from './components/StarItem'
import Filler from './components/Filler'
import Space from '../../../../../components/Space'

const TYPES = ['free', 'standard', 'business']
const NB_CARD_ITEMS = 8

const containerClass = classNames({
  'pricing-offers-card-container': true,
})
const headerContentClass = classNames({
  'pricing-offers-card-header-content': true,
})
const footerContentClass = classNames({
  'pricing-offers-card-footer-content': true,
})
const fillerContainerClass = classNames({
  'pricing-offers-card-filler-container': true,
})
const contentClass = classNames({
  'pricing-offers-card-content': true,
})
const captionClass = classNames({
  'pricing-offers-card-header-caption': true,
})

const BUTTON_COLORS = {
  free: 'grey',
  standard: 'yellow',
  business: 'purple',
}

class Card extends Component {

  render() {

    return (
      <Fragment>
        <div className={containerClass}>
          {this.renderHeader()}
          {this.renderContent()}
          {this.renderFooter()}
        </div>
      </Fragment>
    )
  }

  renderHeader = () => {
    const {
      type,
      title,
      price,
      caption,
    } = this.props
    const headerClass = classNames({
      'pricing-offers-card-header': true,
      [`pricing-offers-card-header-${type}`]: true,
    })
    const diagonalClass = classNames({
      [`pricing-offers-card-header-${type}-diagonal`]: true,
    })

    return (
      <Fragment>
        <div className={headerClass}>
          <div className={headerContentClass}>
            <h5>{title}</h5>
            <h3>{price}</h3>
            <span className={captionClass}>{caption}</span>
          </div>
        </div>
        <div className={diagonalClass}></div>
      </Fragment>
    )
  }

  renderFeature = (feature, index) => {
    const {
      type: cardType,
    } = this.props

    const {
      content,
      type,
    } = feature

    return (
      <Fragment key={`${cardType}_${index}`}>
        <StarItem
          type={type}
          content={content}
        />
        <Space type="s" />
      </Fragment>
    )
  }

  renderContent = () => {
    const { type, features } = this.props
    const nbFiller = (NB_CARD_ITEMS - features.length)
    return (
      <div className={contentClass}>
        {features.map(this.renderFeature)}
        <div className={fillerContainerClass}>
          {Array(nbFiller).fill().map((_, i) => {
            return <Filler key={`filler_${type}_${i}`} />
          })}
        </div>
      </div>
    )
  }

  renderFooter = () => {
    const {
      type,
      button: {
        text,
        link,
        onClick,
      },
    } = this.props

    const footerClass = classNames({
      'pricing-offers-card-footer': true,
      [`pricing-offers-card-footer-${type}`]: true,
    })
    const diagonalClass = classNames({
      [`pricing-offers-card-footer-${type}-diagonal`]: true,
    })

    return (
      <Fragment>
        <div className={diagonalClass} />
        <div className={footerClass}>
          <div className={footerContentClass}>
            <a href={link}>
              <Button
                text={text}
                color={BUTTON_COLORS[type]}
                isExpanded={false}
                onClick={onClick}
              />
            </a>
          </div>
        </div>
      </Fragment>
    )
  }
}

Card.propTypes = {
  type: PropTypes.oneOf(TYPES).isRequired,
  title: PropTypes.string.isRequired,
  price: PropTypes.node.isRequired,
  caption: PropTypes.string,
  features: PropTypes.arrayOf(PropTypes.shape()),
  button: PropTypes.shape({
    text: PropTypes.string,
    link: PropTypes.string,
    onClick: PropTypes.func,
  }).isRequired,
}

export default Card

import './style.css'
import React, { Component } from 'react'
import classNames from 'classnames'

const fillerClass = classNames({
  'pricing-offers-card-filler': true,
})

class Filler extends Component {
  render() {
    return (
      <div className={fillerClass}>
      </div>
    )
  }
}

export default Filler
